import React from "react";
import { Link } from "gatsby-link";

const MatricsCard = ({
  quality_metrics,
  cardClass = "bg-black p-5 lg:p-9",
}) => {
  return (
    <section
      className={`${cardClass} !p-0 flex flex-col justify-center gap-4 md:flex-row xl:gap-0 overflow-hidden`}
    >
      <section className="flex-[0.45] space-y-3 sm:space-y-5 p-5 lg:p-9 !pr-0">
        <h3 className="text-[#C5BBFF] uppercase tracking-wider text-sm font-medium pt-2.5">
          {quality_metrics?.primary?.section_label?.text}
        </h3>
        <h4 className="text-xl sm:text-2.5xl text-white font-semibold tracking-tighter_1">
          {quality_metrics?.primary?.section_heading?.text}
        </h4>
        <p className="max-w-md text-base text-gray-1500 tracking-tighter_1">
          {quality_metrics?.primary?.section_description?.text}
        </p>
        {quality_metrics?.primary?.learn_more?.url && (
          <Link
            to={quality_metrics?.primary?.learn_more?.url}
            target="blank"
            className="flex items-center gap-1 text-base font-bold text-white w-fit"
          >
            Learn more{" "}
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.02589 6.28799L0.534393 11.3582C0.517431 11.3716 0.497045 11.3799 0.475579 11.3822C0.454112 11.3845 0.432435 11.3806 0.413036 11.3712C0.393637 11.3617 0.377304 11.3469 0.365912 11.3286C0.35452 11.3103 0.348532 11.2891 0.348634 11.2675V10.1544C0.348634 10.0838 0.381754 10.0161 0.436474 9.97294L5.62045 5.92511L0.436474 1.87728C0.380314 1.83408 0.348634 1.7664 0.348634 1.69584V0.582718C0.348634 0.486238 0.459514 0.432958 0.534393 0.491998L7.02589 5.56223C7.08106 5.60527 7.12569 5.66032 7.15639 5.7232C7.18709 5.78608 7.20305 5.85513 7.20305 5.92511C7.20305 5.99508 7.18709 6.06414 7.15639 6.12702C7.12569 6.1899 7.08106 6.24495 7.02589 6.28799Z"
                fill="#FFF"
              />
            </svg>
          </Link>
        )}

        {quality_metrics?.items && (
          <div className="hidden grid-cols-2 gap-6 py-8 xl:py-16 sm:grid gap-y-5 xl:gap-y-10 lg:gap-x-12 xl:gap-x-24">
            {quality_metrics?.items?.map((item, index) => {
              return (
                <div className="flex items-center gap-x-3" key={index}>
                  <img
                    src={item?.icon?.url}
                    width={item?.icon?.dimensions?.width || 0}
                    height={item?.icon?.dimensions?.height || 0}
                    className="w-8 h-8"
                    alt="matricx-card"
                  />
                  <p className="text-[#F5F5F5] text-base tracking-tighter_1">
                    {item?.item?.text}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </section>
      <section className="flex-[0.55] mt-auto -mb-4">
        <img
          src={quality_metrics?.primary?.section_image?.url}
          alt={quality_metrics?.primary?.section_image?.url || "quality_metrics"}
          width={quality_metrics?.primary?.section_image?.dimensions?.width}
          height={quality_metrics?.primary?.section_image?.dimensions?.height}
          className="w-full md:w-[475px] xl:w-[616px]  h-auto md:h-[375px] xl:h-[434px]"
        />
      </section>

      {quality_metrics?.items && (
        <div className="flex flex-col p-5 pb-2 sm:hidden gap-y-5">
          {quality_metrics?.items?.map((item, index) => {
            return (
              <div className="flex items-center gap-x-3" key={index}>
                <img
                  src={item?.icon?.url}
                  width={item?.icon?.dimensions?.width || 0}
                  height={item?.icon?.dimensions?.height || 0}
                  className="w-8 h-8"
                  alt="icon"
                />
                <p className="text-[#F5F5F5] text-base tracking-tighter_1">
                  {item?.item?.text}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default MatricsCard;
